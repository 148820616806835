import React, { CSSProperties } from 'react';
import { ReactComponent as Logo } from '../assets/ue_logo.svg';

//Refactor needed, combine this into its own file and reference there to multiple modules
function getUrlParameter(sParam):string {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? '' : decodeURIComponent(sParameterName[1]);
      }
  }
  return '';
};


function visibiltyBasedOnCode(showIfCodeExists:boolean):CSSProperties
{
  if ((getUrlParameter('code').length>0)===showIfCodeExists)
  {
    return {visibility: 'visible'};
  }
  return{visibility: 'collapse', display:'none'};
  }


export class ConnectionStatus extends React.Component<{}, {value: string}> {
  constructor(props) {
    super(props);
    this.state = {value : ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(e) {
    this.setState({value :e.target.value.toLowerCase()}) ;
    }

  handleSubmit(e) {
    e.preventDefault();
    window.location.search="?code=" + this.state.value;
  }
  handleCancel(e) {
    e.preventDefault();
    this.setState({value :''}) ;
    window.location.search="";
  }



  render() {
    return (
      <div className="fullscreen">
        <div className="app-icon">
          <Logo className="logo" />
        </div>
      <div style={visibiltyBasedOnCode(true)}>Trying to connect...
      <br></br><div style={{ backgroundColor: '#191919',
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
    margin: '10px'}}><button style = {{backgroundColor: '#191919',
    border: 'solid 1px #141414',
    color: '#FFF'}} onClick={this.handleCancel}>Cancel</button></div>
      </div>
      <div style={visibiltyBasedOnCode(false)}>        
        <form onSubmit={this.handleSubmit} >
        <input type="text" 
        placeholder="Access Code" style=
        {{textAlign: 'center'}}
         onChange={this.handleChange} value={this.state.value}/>
         <input type="submit"/>
      </form></div> 

     </div>
    );
  }
};